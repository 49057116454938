<template>
  <div id="CashInLieuOfvacationRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit cash in lieu request entry' : isEditable ? 'cash in lieu request entry': 'view cash in lieu request entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'">
          <div>
            <!--<transition name="slide-fade">
            <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
          </transition>-->
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :additionalData="additionalData"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row" v-if="requestCanBeRaisedForEmployee && !showGenerateVacationButton">
      <div class="col">
        <form id="entryForm" onsubmit="event.preventDefault();" novalidate>
          <fieldset v-bind:disabled="isSavingRequest">
            <div class="form-group row mt-4">
              <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>no. of days</label>
              <input type="number" class="form-control col-md-1" v-model="request.numberOfDays" required min="1" :max="vacationMaster.unScheduledVacationDays" />
              <span v-if="request.numberOfDays > vacationMaster.unScheduledVacationDays" class="text-danger font-weight-bold">{{ '( max: ' + vacationMaster.unScheduledVacationDays + ' )'  }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3">remarks</label>
              <textarea class="form-control col-md-5" rows="4" v-model="request.reasonForChange" v-bind:disabled="disableFields"></textarea>
            </div>
            <div class="form-group col-md-12 mt-4 text-center">
              <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
            </div>
            <div class="col-md-12 text-center">
              <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Request for cash in lieu of vacation<span class="spinner"></span></button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
    <div v-if="requestCanBeRaisedForEmployee && showGenerateVacationButton" class="text-center">
      <button class="btn btn-primary d-inline mr-2" @click="generateVacationData" v-bind:disabled="isGeneratingVacationData">Generate vacation data</button>
      <!--<span v-if="showGenerateCashInLieuRequestButton">
        Or <router-link :to="{ name: 'enterCashInLieuOfVacationRequest', params: { employeeId: employeeInfo.employeeId || currentUser.employeeId }}">request cash in-lieu</router-link>-->
      <!--</span>-->      <!--Or <router-link :to="'/requests/vacation/cashinlieu/?employeeId='+ employeeInfo.employeeId">request cash in-lieu</router-link>-->
    </div>

    <!--<div v-if="isCompleted" class="text-right">
    <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
  </div>-->
  </div>
</template>

<style scoped lang="scss">

  .action-btn {
    cursor: pointer;
    font-size: 30px;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'

  import { employeeService, cashInLieuRequestService, vacationMasterService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import VacationRequestEntry from '@/components/vacation/SingleEntry'
  import VacationRequestEntryDisplay from '@/components/vacation/SingleEntryDisplay'
  import EmployeeInfoDisplay from '@/components/vacation/cashInLieu/EmployeeInfoDisplay'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'
  import VacationMasterMixin from '@/mixins/leaveRequest/vacationMaster'


  export default {
    name: 'CashInLieuOfvacationRequestView',
    components: {
      EmployeeInfoDisplay,
      VacationRequestEntry,
      VacationRequestEntryDisplay
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin, VacationMasterMixin],
    data: () => ({
      request: {
        id: null,
        vacationYear: null,
        numberOfDays: null,
        reasonForChange: '',
        intendedApproverId: '',
        intendedApproverName: '',
        requestType: Enums.RequestType.CashInLieuOfVacation.value,
        requestActions: []
      },
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unScheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        version: null,
        cashInLieuRequests: []
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        employmentDate: '',
        sbuStartDate: '',
        locationName: '',
        locationId: '',
        workScheduleName: '',
        workScheduleId: '',
        employeeTypeId: '',
        employeeTypeName: '',
        supervisorName: '',
        payrollCountryId: null,
        payrollCountry: null,
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isFieldEmployee: false,
        isOnForeignService: false
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0
      },
      showGenerateVacationButton: false,
      isGeneratingVacationData: false,
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee && employee.isFieldEmployee)
        }
        return []
      },
      requestCanBeRaisedForEmployee() {
        // An employee has been selected
        if (this.employeeInfo.employeeId) {
          // Only regular field employees not on foreign service can raise a field vacation option scheduling request
          if (this.employeeInfo.isRegularEmployee && this.employeeInfo.isFieldEmployee) {
            if (this.employeeInfo.isOnForeignService) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeBeingOnForeignService, this.request.requestType)
              return false
            }
            if (!this.appSettings.vacation.allowPreAnniversaryScheduling && (new Date(this.employeeInfo.employmentDate)).getFullYear() === (new Date()).getFullYear()) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToPreAnniversary, this.request.requestType)
              return false
            }
            //if (this.employeeInfo.workScheduleId == Enums.WorkSchedule.WS28x28.value) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.EmployeesOn28DaysScheduleCannotRequestCashInLieu, this.request.requestType)
            //  return false
            //}
            if (this.vacationMaster.id && this.vacationMaster.unScheduledVacationDays <= 0) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUnscheduledVacationDays, this.request.requestType)
              return false
            }
            //if (this.vacationMaster.cashInLieuRequests.length) {
            //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.ValidCashInLieuRequestAlreadyExists, this.request.requestType)
            //  return false
            //}
            if (!this.vacationMaster.id && !this.showGenerateVacationButton) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
              return false;
            }
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return true
          }
          else {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeTypeOrScheduleOrLocation, this.request.requestType)
            return false
          }
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    watch: {
      'vacationMaster.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo.employeeId)
          }
        }
      },
    },
    methods: {
      resetOtherData() {
        this.resetEmployeeData()
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.payrollCountryId = ''
        this.employeeInfo.payrollCountry = ''
        this.vacationMaster.id = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unScheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.fieldVacationOption = ''
        this.vacationMaster.version = null
        this.vacationMaster.cashInLieuRequests.splice(0, this.vacationMaster.cashInLieuRequests.length)
        this.showGenerateVacationButton = false
      },
      fetchEmployeeData(data) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        let tasks = []
        let employeeId = data
        if (typeof data === 'object') {
          employeeId = data.employeeId
        }
        tasks.push(employeeService.getEmployeeWithWorkType(employeeId, true))
        tasks.push(vacationMasterService.getVacationMasterFor(employeeId, self.vacationMaster.vacationYear))
        Promise.all(tasks).then((results) => {
          self.resetData()
          const employee = results[0].data
          var vacationMaster = results[1]
          self.setData(employee, vacationMaster)
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setData(employee, vacationMaster) {
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.sbuStartDate = employee.sbuStartDate
        this.employeeInfo.payrollCountryId = employee.payrollCountryId
        this.employeeInfo.payrollCountry = employee.payrollCountry
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        this.request.intendedApproverId = employee.supervisorId
        this.request.intendedApproverName = employee.supervisorName
        if (vacationMaster) {
          this.showGenerateVacationButton = false
          this.setVacationData(vacationMaster)
        }
        else {
          if (employee.isRegularEmployee && employee.isFieldEmployee) {
            this.showGenerateVacationButton = true
          }
        }
      },
      setVacationData(vacationMaster) {
        this.vacationMaster.id = vacationMaster.id
        this.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        this.vacationMaster.travelDays = vacationMaster.travelDays
        this.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        this.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        this.vacationMaster.unScheduledVacationDays = this.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        this.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        if (vacationMaster.cashInLieuRequests && vacationMaster.cashInLieuRequests.length) {
          for (const cashInLieuRequest of vacationMaster.cashInLieuRequests.filter(cashInLieuRequest => !cashInLieuRequest.isCancelled)) {
            this.vacationMaster.cashInLieuRequests.push(cashInLieuRequest)
          }
        }
        //this.vacationMaster.buyOutDays = vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule_CashInLieu || this.employeeInfo.workScheduleId === Enums.WorkSchedule.WS28x28.value ? vacationMaster.unscheduleVacationDays : (vacationMaster.fieldVacationOption === Enums.FieldVacationScheduleOption.Schedule14_Days ? Math.min(vacationMaster.vacationDays + vacationMaster.travelDays - 14, vacationMaster.unscheduleVacationDays) : 0)
        this.vacationMaster.version = vacationMaster.version
      },
      submitRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            self.closeMessage()
            self.isSavingRequest = true
            //eventBus.$emit(Events.LongOperationStarted, `Requesting cash in lieu of vacation for ${self.vacationYear}`)
            let request = { ...self.request }
            request.employeeId = self.employeeInfo.employeeId
            request.vacationYear = self.vacationMaster.vacationYear
            cashInLieuRequestService.schedule(request).then((result) => {
              self.resetData()
              self.showSuccessMessage('request submitted successfully')
              self.isSavingRequest = false
            }).catch((error) => {
              console.log(error)
              self.showErrorMessage(error)
            }).finally(() => {
              //eventBus.$emit(Events.LongOperationCompleted)
              self.isSavingRequest = false
            })
          }

        })
      },
      async validateRequest() {
        const approverField = document.getElementById('supv_search')
        if (approverField) {
          let invalidApprover = false
          if (!this.request.intendedApproverId) {
            approverField.setCustomValidity("No approver selected")
            invalidApprover = true
          }
          if (this.request.intendedApproverId.toLowerCase() == this.employeeInfo.employeeId.toLowerCase()) {
            approverField.setCustomValidity("You cannot approve your own request")
            invalidApprover = true
          }
          if (!invalidApprover) {
            approverField.setCustomValidity("")
          }
        }
        const requestForm = document.getElementById('entryForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
          var errorElements = document.querySelectorAll(
            ".form-control:invalid");
          $('html, body').animate({
            scrollTop: $(errorElements[0]).offset().top - 20
          }, 500);
          return false
        }
        else {
          const approverDirectReports = await employeeService.getEmployeeDirectReports(this.request.intendedApproverId)
          if (approverDirectReports && approverDirectReports.length) {
            return true
          }
          else {
            this.showErrorMessage('invalid approver. approver is not a supervisor')
            return false
          }
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        //  vm.fetchEmployeeData(store.state.userModule.currentUser)
        if (vm.$route.params.employeeId) {
          if (vm.$route.params.vacationYear) {
            vm.vacationMaster.vacationYear = vm.$route.params.vacationYear
          }
          vm.fetchEmployeeData(vm.$route.params.employeeId)
        }
        else {
          //vm.pageIsFullyLoaded = true
          //vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser.employeeId)
        }
      })
    },
    mounted() {
      const today = new Date()
      const currentYear = today.getFullYear()
      if (today.getMonth() + 1 <= this.appSettings.vacation.lastMonthForVacationCarryOver) {
        this.additionalData.vacationYears.push(currentYear - 1)
      }
      this.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= this.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        this.additionalData.vacationYears.push(currentYear + 1)
      }
      if (!this.vacationMaster.vacationYear) {
        this.vacationMaster.vacationYear = currentYear
      }
    }
  }
</script>
